import React, {
    createContext, useState, useContext, useEffect, useCallback, useMemo
} from 'react';
import { UmbracoContext } from './Umbraco';

// External components
import { BiCalendarCheck, BiCalendarMinus, BiCalendarX } from 'react-icons/bi';
import { MdUpdate } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';
import Fuse from 'fuse.js';
import Mark from "mark.js";

export const DocumentsContext = createContext();
export const DocumentsProvider = ({ children }) => {
    const { routes } = useContext(UmbracoContext);
    const [allDocs, setAllDocs] = useState(null);
    const [fishSpecies, setFishSpecies] = useState(null);
    const [categories, setCategories] = useState(null);
    const [statuses, setStatuses] = useState(null);
    const [areas, setAreas] = useState(null);

    const formatUmbracoDate = useCallback(
        (d, spacer = '.', returnValue = false, fallback) => {
            if (d) {
                let num = '[0-9]';
                let regex = new RegExp(
                    `(${num}{2})${spacer}(${num}{2})${spacer}(${num}{4}).*`,
                    'g'
                ); // Matches 28.01.2023 00:00:00
                let day = d.replace(regex, '$1');
                let month = d.replace(regex, '$2');
                let year = d.replace(regex, '$3');
                if (returnValue) {
                    // Returns 2023.01.28
                    let dateVal = `${year}.${month}.${day}`;
                    return dateVal;
                } else {
                    // Returns 28.01.2023
                    let returnDate = `${day}.${month}.${year}`;
                    if (returnDate === '01.01.0001') {
                        return fallback;
                    }
                    else {
                        return returnDate;
                    }
                }
            }
        },
        []
    );
    const [defaultSelectedStatuses, setDefaultSelectedStatuses] = useState([]);
    const getDateStatus = useCallback(
        (validFrom, validTo, key, todayValue) => {
            let validFromValue = formatUmbracoDate(validFrom, '.', true).split(".").join("");
            let validToValue = formatUmbracoDate(validTo, '.', true).split(".").join("");

            let label = '';
            let suffix = '';
            let icon = null;
            let checked = false;
            let order = 0;
            if (validToValue === "00010101") {
                validToValue = "99999999";
            }
            // USPESIFISERT
            if (validFromValue === '00010101' && validToValue === '99999999') {
                label = 'Uspesifisert';
                suffix = '--undefined';
                icon = <BiCalendarMinus />;
                checked = false;
                order = 0;
            }

            // If validTo is older / lower number than todays date
            else if (validToValue < todayValue) {
                label = 'Utgått';
                suffix = '--expired';
                icon = <BiCalendarX />;
                checked = false;
                order = 3;
            }

            // KOMMENDE
            // If validFrom is newer / larger number than todays date
            else if (validFromValue > todayValue && validToValue > todayValue) {
                label = 'Kommende';
                suffix = '--upcoming';
                icon = <MdUpdate />;
                checked = true;
                order = 2;
            }

            // GJELDENDE
            else if (todayValue < validToValue && validFromValue <= todayValue) {
                // If today value is older / lower than validTo
                label = 'Gjeldende';
                suffix = '--valid';
                icon = <BiCalendarCheck />;
                checked = true;
                order = 1;
            }


            // Debug
            // return (
            // 	<pre>
            // 		{JSON.stringify(
            // 			{ validFromValue, validToValue, todayValue, status },
            // 			null,
            // 			1
            // 		)}
            // 	</pre>
            // );
            let item = { label, suffix, icon, checked, order };
            // console.group();
            // console.log('item', item);
            // console.log('validToValue', validToValue.split(".").join(""));
            // console.log('todayValue', todayValue);
            // console.log('key', key);
            // console.log("validToValue < todayValue", validToValue < todayValue);
            // console.log("validFromValue > todayValue", validFromValue > todayValue)
            // console.groupEnd()
            if (checked) {
                setDefaultSelectedStatuses((selected) => {
                    if (selected.includes(label)) {
                        return selected;
                    } else {
                        return [...selected, label];
                    }
                });
            }
            return item;
        },
        [formatUmbracoDate]
    );
    const formatDocs = useCallback(
        (docs) => {
            let formattedDocs = [];
            let fishSpeciesWithCount = {};
            let categoriesWithCount = {};
            let statusesWithCount = {};
            let areasWithCount = {};
            const addObjWithCount = (arr, key, newObj) => {
                if (arr) {
                    arr.forEach((f) => {
                        if (!newObj[f[key]]) {
                            newObj[f[key]] = { docCount: 1, ...f };
                        } else if (newObj[f[key]]) {
                            newObj[f[key]].docCount = newObj[f[key]].docCount + 1;
                        }
                    });
                }
            };
            docs.forEach((doc) => {
                let todayValue = new Date()
                    .toLocaleDateString('nb-NO', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })
                    .split('.')
                    .reverse()
                    .join('');
                let obj = {
                    ...doc,
                    validFromFormatted: formatUmbracoDate(doc.validFrom, '.', false, '01.01.0001'),
                    validToFormatted: formatUmbracoDate(doc.validTo, '.', false, '99.99.9999'),
                    status: getDateStatus(doc.validFrom, doc.validTo, doc.title, todayValue),
                };
                formattedDocs.push(obj);
                addObjWithCount(obj.fishSpecies, 'speciesName', fishSpeciesWithCount);
                addObjWithCount(obj.categories, 'title', categoriesWithCount);
                addObjWithCount([obj.status], 'label', statusesWithCount);
                addObjWithCount(obj.areas, 'title', areasWithCount);
                // if (obj.categories) {
                // 	console.log('obj.categories', obj.categories);
                // }
                // console.log('[obj.status]', [obj.status]);
            });
            const setNoneEmptyObject = (obj, setState) => {
                let emptyObj = Object.keys(obj).length === 0;
                if (!emptyObj) {
                    setState(obj);
                }
            };
            const setNoneEmptyArray = (arr, setState) => {
                let emptyArr = arr.length === 0 ? true : false;
                if (!emptyArr) {
                    setState(arr);
                }
            };
            setNoneEmptyObject(fishSpeciesWithCount, setFishSpecies);
            setNoneEmptyObject(categoriesWithCount, setCategories);
            setNoneEmptyObject(statusesWithCount, setStatuses);
            setNoneEmptyObject(areasWithCount, setAreas);
            // setNoneEmptyArray(formattedDocs.sort((a, b) => a.sortorder < b.sortorder ? +1 : -1).slice(0, 2), setAllDocs);
            setNoneEmptyArray(formattedDocs, setAllDocs);
        },
        [getDateStatus, formatUmbracoDate]
    );
    useEffect(() => {
        let documentsPage = routes?.filter(r => r.alias.toLowerCase() === 'documentscollection');
        if (documentsPage && documentsPage[0]?.children) {
            formatDocs(documentsPage[0]?.children);
        }
    }, [routes, formatDocs]);



    /* -------------------------------------------------------------------------- */
    /*                                  VERSIONS                                  */
    /* -------------------------------------------------------------------------- */

    const returnWithStatus = useCallback(
        (obj) => {
            let todayValue = new Date()
                .toLocaleDateString('nb-NO', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                })
                .split('.')
                .reverse()
                .join('');
            return { ...obj, status: getDateStatus(obj.validFrom, obj.validTo, obj.title, todayValue) };
        },
        [getDateStatus],
    );
    const [docReplacements, setDocReplacements] = useState([]);
    useEffect(() => {
        let versions = [];
        allDocs?.forEach(doc => {
            if (doc.replacesDoc) {
                versions.push({
                    title: doc.title,
                    id: doc.id,
                    rundskrivNumber: doc.rundskrivNumber,
                    status: doc.status,
                    replacesDoc: returnWithStatus(doc.replacesDoc),
                    url: doc.url
                });
            }
        });
        if (versions) {
            setDocReplacements(versions);
        }
    }, [allDocs, getDateStatus, returnWithStatus]);


    /* -------------------------------------------------------------------------- */
    /*                                   SIDEBAR                                  */
    /* -------------------------------------------------------------------------- */

    const [showSidebar, setShowSidebar] = useState(false);

    const { isTouch } = useContext(UmbracoContext);
    const focusSearchBar = useCallback(() => {
        if (!isTouch) {
            document.querySelector('.library .library__search input').focus();
        }
    }, [isTouch]);

    /* -------------------------------------------------------------------------- */
    /*                                   SEARCH                                   */
    /* -------------------------------------------------------------------------- */
    const [searchQuery, setSearchQuery] = useState(undefined);
    const [filteredDocs, setFilteredDocs] = useState();
    const [librarySearchParams, setLibrarySearchParams] = useSearchParams({});
    const [searchHits, setSearchHits] = useState(0);
    useEffect(() => {
        let q = librarySearchParams.get("search");
        if (q) {
        }
    }, [librarySearchParams]);

    const limitToKeys = useMemo(() => [
        'title',
        'rundskrivNumber',
        'description',
        'fishSpecies',
    ], []);

    const highLightSearch = useCallback(
        () => {
            setTimeout(() => {
                let elements = document.querySelectorAll('.library__docs__title');
                elements?.forEach(el => {
                    let mark = new Mark(el);
                    mark?.unmark({
                        accuracy: "partially",
                        done: (e) => {
                            if (searchQuery?.length > 0) {
                                mark?.mark(searchQuery, {
                                    accuracy: "partially",
                                });
                            }
                        }
                    });
                });
            }, 100);
        },
        [searchQuery],
    );

    const doSearch = useCallback(
        (q) => {
            if (filteredDocs && limitToKeys) {
                let searched = new Fuse(filteredDocs, {
                    keys: limitToKeys,
                    threshold: 0.4,
                }).search(q);
                setLibrarySearchParams({ search: q });
                setShownDocs(searched?.map(s => s.item));
                setSearchHits(searched?.length);
                setShowSidebar(false);
                highLightSearch();
            }
        },
        [setLibrarySearchParams, setSearchHits, limitToKeys, filteredDocs, highLightSearch]
    );
    const [shownDocs, setShownDocs] = useState(allDocs);

    useEffect(() => {
        let q = librarySearchParams.get("search");
        if (q) {
            doSearch(q);
            setSearchQuery(q);
        }
    }, [librarySearchParams, doSearch]);


    const resetDocs = useCallback(
        () => {
            highLightSearch();
            setShownDocs(allDocs);
        },
        [setShownDocs, allDocs, highLightSearch],
    );

    const [docRoute, setDocRoute] = useState(null);



    return (
        <DocumentsContext.Provider
            value={{
                docReplacements,
                allDocs,
                fishSpecies,
                categories,
                statuses,
                areas,
                defaultSelectedStatuses, librarySearchParams, setLibrarySearchParams,
                doSearch, shownDocs, setShownDocs, resetDocs, searchHits, setSearchHits,
                limitToKeys, filteredDocs, setFilteredDocs, searchQuery, setSearchQuery,
                setShowSidebar, showSidebar, focusSearchBar, docRoute, setDocRoute, getDateStatus, returnWithStatus

            }}
        >
            {children}
        </DocumentsContext.Provider>
    );
};
