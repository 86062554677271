import React from 'react';
import './sass/main.scss';
import App from './App';
import { UmbracoProvider } from 'contexts/Umbraco';
import { BrowserRouter } from 'react-router-dom';
import { ClearCacheProvider } from 'react-clear-cache';
import { DocumentsProvider } from 'contexts/DocumentsContext';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <ClearCacheProvider duration={5000} basePath='/react-build' auto={false}>
    {/* Checks cache every 5 seconds, resets cache on reload */}
    <BrowserRouter>
      <UmbracoProvider>
        <DocumentsProvider>
          <App />
        </DocumentsProvider>
      </UmbracoProvider>
    </BrowserRouter>
  </ClearCacheProvider>
);
