/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

// External components
import { Helmet } from "react-helmet";

const Meta = ({ description, image, title, seoTitle }) => {
    return (
        <Helmet>
            <title>{title}</title>
            {title && <meta name="title" content={title} />}
            {seoTitle && <meta property="og:title" content={seoTitle} />}
            {seoTitle && <meta property="twitter:title" content={seoTitle} />}

            <meta property="og:type" content="website" />

            {description && <meta name="description" content={description} />}
            {description && (
                <meta property="og:description" content={description} />
            )}
            {description && (
                <meta property="twitter:description" content={description} />
            )}

            {image && (
                <meta
                    property="og:image"
                    content={image?.url + "&width=1200&height=627"}
                />
            )}
            {image && (
                <meta
                    property="twitter:image"
                    content={
                        window.location.href +
                        image?.url +
                        "&width=1200&height=630&quality=65"
                    }
                ></meta>
            )}
            <meta property="twitter:card" content="summary_large_image" />
        </Helmet>
    );
};

export default Meta;
