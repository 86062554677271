import React, { useContext, useEffect } from "react";
import { Route, useLocation, Routes } from "react-router-dom";

// External components
import loadable from "@loadable/component";
import CookieConsent, { resetCookieConsentValue } from "react-cookie-consent";
import { useClearCacheCtx } from "react-clear-cache";

// Internal components
import Meta from "components/Meta";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";
import { DocumentsContext } from "contexts/DocumentsContext";

// Lazy Internal components
const DevMode = loadable(() => import("components/Dev/DevMode"));
const Nav = loadable(() => import("components/Nav"));
const Footer = loadable(() => import("components/Footer"));
const CheckLandinger = loadable(() =>
  import("components/Landinger/CheckLandinger")
);
const NotFound = loadable(() => import("components/NotFound"));
const SetCurrentRoute = loadable(() => import("components/SetCurrentRoute"));

// Routes
const Landinger = loadable(() => import("routes/Landinger"));
const Page = loadable(() => import("routes/Page"));
const Home = loadable(() => import("routes/Home"));
const Hotkeys = loadable(() => import("react-hot-keys"));

// Code splitted routes
const Article = loadable(() => import("routes/Article"));
const Articles = loadable(() => import("routes/Articles"));
const Library = loadable(() => import("routes/Library"));
const Doc = loadable(() => import("routes/Doc"));
const LibrarySubscription = loadable(() =>
  import("routes/LibrarySubscription")
);

const ReturnPageComponent = ({ page }) => {
  if (page.alias.toLowerCase() === "article") {
    return (
      <>
        <Meta
          title={page.title}
          seoTitle={page.seoTitle}
          description={page.seoDescription}
          image={page.seoImage}
        />
        <Article content={page} />
        <Footer />
      </>
    );
  }
  if (page.alias.toLowerCase() === "documentscollection") {
    return (
      <>
        <div className="fh-page">
          <Meta
            title={page.title}
            seoTitle={page.seoTitle}
            description={page.seoDescription}
            image={page.seoImage}
          />
          <div className="fh-page_content mb-5">
            <Library page={page} />
          </div>
          <Footer />
        </div>
      </>
    );
  }
  if (page.alias.toLowerCase() === "varslingsside") {
    return (
      <>
        <div className="fh-page">
          <Meta
            title={page.title}
            seoTitle={page.seoTitle}
            description={page.seoDescription}
            image={page.seoImage}
          />
          <div className="fh-page_content mb-5">
            <LibrarySubscription />
          </div>
          <Footer />
        </div>
      </>
    );
  }
  // if (page.alias.toLowerCase() === 'document') {
  // 	return (
  // 		<>
  // 			<div className='fh-page'>
  // 				<Meta
  // 					title={page.title}
  // 					seoTitle={page.seoTitle}
  // 					description={page.seoDescription}
  // 					image={page.seoImage}
  // 				/>
  // 				<div className='fh-page_content'>
  // 					<Doc page={page} />
  // 				</div>
  // 				<Footer />
  // 			</div>
  // 		</>
  // 	);
  // }
  if (page.alias.toLowerCase() === "publishedcontent") {
    return (
      <>
        <div className="fh-page">
          <Meta
            title={page.title}
            seoTitle={page.seoTitle}
            description={page.seoDescription}
            image={page.seoImage}
          />
          <div className="fh-page_content">
            <Doc page={page} />
          </div>
          <Footer />
        </div>
      </>
    );
  }
  if (page.alias.toLowerCase() === "articles") {
    return (
      <>
        <div className="fh-page">
          <Meta
            title={page.title}
            seoTitle={page.seoTitle}
            description={page.seoDescription}
            image={page.seoImage}
          />
          <div className="fh-page_content">
            <h1>{page.title}</h1>
            <Articles page={page} />
          </div>
          <Footer />
        </div>
      </>
    );
  }
  return <Page page={page} />;
};

function App() {
  const { data, routes } = useContext(UmbracoContext);
  const { allDocs, setDocRoute, docRoute } = useContext(DocumentsContext);
  useEffect(() => {
    console.log(
      '%cPress: "alt" + "shift" + "r" to reset the cookie banner',
      "color:green;font-weight:bold;border:1px solid black;padding: 1rem;"
    );
  }, []);
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
  const { pathname } = useLocation();
  const testCache = React.useCallback(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
      console.log("Was using old version, clearing cache");
    } else {
      // console.log('Using current version');
    }
  }, [isLatestVersion, emptyCacheStorage]);
  useEffect(() => {
    testCache();
  }, [pathname, testCache]);

  const location = useLocation();
  const anchorElement = document.getElementById(
    location?.hash.replace("#", "")
  );
  let hash = location.hash;
  useEffect(() => {
    function scrollToTargetAdjusted(element) {
      element.scrollIntoView({
        behavior: "instant",
        block: "start",
        inline: "start",
      });
    }

    if (hash) {
      // document
      //     .getElementById(hash.replace("#", ""))
      //     .scrollIntoView({ behavior: "smooth" });
      if (anchorElement) {
        scrollToTargetAdjusted(anchorElement);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash, anchorElement]);

  useEffect(() => {
    if (routes) {
      let docPage = routes?.filter(
        (r) => r.alias.toLowerCase() === "documentscollection"
      );
      if (docPage?.length > 0) {
        setDocRoute(docPage[0]);
      }
    }
  }, [routes, setDocRoute]);
  const removeHost = (u) => {
    if (window.location.hostname === "localhost") {
      let replaced = u.replace("https://www.fiskehav.no", "");
      return replaced;
    } else {
      return u;
    }
  };
  useEffect(() => {
    if (data) {
      document.body.classList.add("loaded");
    }
  }, [data]);

  return (
    <>
      {data && (
        <>
          <Hotkeys
            allowRepeat={true}
            keyName="alt+shift+r"
            onKeyDown={() => {
              resetCookieConsentValue("acceptCookies");
              window.location.reload();
            }}
          ></Hotkeys>
          <div className="cookie__consent__wrapper">
            <CookieConsent
              location="bottom"
              buttonText="Jeg forstår"
              cookieName="acceptCookies"
              style={{
                background: "#2B373B",
                width: "max-content",
                display: "flex",
                bottom: "0 env()",
                justifyContent: "center",
                flexWrap: "wrap",
                maxWidth: "100vw",
                alignItems: "center",
                padding: "0.5rem",
              }}
              disableButtonStyles
              buttonClasses="btn btn-primary rounded-pill px-4 mb-4 mb-sm-2"
            >
              <p className="mb-0" style={{ maxWidth: "40ch" }}>
                Denne nettsiden bruker informasjonskapsler{" "}
                <span style={{ opacity: 0.8 }}>(cookies)</span> for å bedre
                brukeropplevelsen.{" "}
                <a
                  href="https://nettvett.no/slik-administrer-du-informasjonskapsler/"
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{
                    color: "#2abbe8",
                    textDecoration: "underline",
                  }}
                  className="d-inline-block d-sm-inline"
                >
                  Les mer
                </a>
              </p>
              {/* <span style={{ fontSize: "10px" }}>
                            This bit of text is smaller :O
                        </span> */}
            </CookieConsent>
          </div>
          <DevMode />
          <Nav />
          <Routes>
            {data.url && (
              <Route
                path={removeHost(data.url)}
                exact
                element={
                  <>
                    <SetCurrentRoute page={data} />
                    <CheckLandinger isLandinger={data.useAsLandinger}>
                      <Home />
                      <Footer home />
                    </CheckLandinger>
                  </>
                }
              />
            )}
            {docRoute && (
              <Route
                path={`${removeHost(docRoute.url)}/varsel-paa-sms-og-epost`}
                exact
                element={
                  <div className="fh-page">
                    <div className="fh-page_content mb-5">
                      <LibrarySubscription />
                    </div>
                    <Footer />
                  </div>
                }
              ></Route>
            )}
            {allDocs?.map((page) => (
              <Route
                path={removeHost(page.url)}
                exact
                key={page.url + "route"}
                element={
                  <>
                    <SetCurrentRoute page={page} />
                    <div className="fh-page">
                      <Meta
                        title={page.title}
                        seoTitle={page.seoTitle}
                        description={page.seoDescription}
                        image={page.seoImage}
                      />
                      <div className="fh-page_content">
                        <Doc page={page} />
                      </div>
                      <Footer />
                    </div>
                  </>
                }
              />
            ))}
            {routes?.map((page) => (
              <Route
                path={removeHost(page.url)}
                exact
                key={page.url + "route"}
                element={
                  <>
                    <SetCurrentRoute page={page} />
                    <CheckLandinger isLandinger={page.useAsLandinger}>
                      {page.useAsLandinger ? (
                        <Landinger />
                      ) : (
                        <>
                          <ReturnPageComponent page={page} />
                        </>
                      )}
                    </CheckLandinger>
                  </>
                }
              />
            ))}

            <Route path="*" element={<NotFound routes={routes} />} />
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
