import React, {
	createContext,
	useState,
	useEffect,
	useRef,
	useCallback,
	useMemo,
} from 'react';

// External components
import { useScrollDirection } from 'react-use-scroll-direction';
import parse from 'html-react-parser';
// External links
import axios from 'axios';
export const UmbracoContext = createContext();
export const UmbracoProvider = ({ children }) => {
	/* ********************************** */
	/*             AXIOS FETCH            */
	/* ********************************** */

	const [data, setData] = useState(null);
	const [mainMenu, setMainMenu] = useState(null);
	const [landingerData, setLandingerData] = useState(null);
	const [dataByHarbours, setDataByHarbours] = useState(null);
	const [useAsLandinger, setUseAsLandinger] = useState(false);
	const f = useMemo(() => {
		return {
			baatnavn: 'baatnavn',
			fangstfelt: 'fangstfelt',
			fiskeslag: 'fiskeslag',
			konserveringsMaate: 'konserveringsMaate',
			leveringsDato: 'leveringsDato',
			leveringsTid: 'leveringsTid',
			mottak: 'mottak',
			mottaksStasjonsNr: 'mottaksStasjonsNr',
			produktTilstand: 'produktTilstand',
			registermerke: 'registermerke',
			rundVekt: 'rundVekt',
			sortiment: 'sortiment',
		};
	}, []);
	const datesArray = useCallback(
		(arr) => {
			return arr.map((item) => new Date(item[f.leveringsDato]));
		},
		[f]
	);
	const formatDate = (date) => {
		if (date.includes('-')) {
			let dateArray = date.split('-');
			let year = dateArray[0];
			let month = dateArray[1];
			let day = dateArray[2];
			return `${day}.${month}.${year}`;
		} else {
			return '';
		}
	};
	const formatNewDate = React.useCallback((d) => {
		return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
	}, []);

	// GET ARTICLES
	// const [articles, setArticles] = useState(null);
	// useEffect(() => {
	// 	axios({
	// 		method: 'get',
	// 		headers: {
	// 			'Content-Type': 'application/x-www-form-urlencoded',
	// 			'Access-Control-Allow-Origin': '*',
	// 			mode: 'no-cors',
	// 			// cache: "no-cache",
	// 		},
	// 		url: '/headless/collections/Articles',
	// 	})
	// 		.then((response) => {
	// 			setArticles(response.data);
	// 		})
	// 		.catch((e) => {
	// 			console.error(e);
	// 		});
	// }, []);

	// Get all pages and content
	useEffect(() => {
		// const response = require('../api/headless/data.json');
		// setData(response);
		// setDevData(response);
		axios({
			method: 'get',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Access-Control-Allow-Origin': '*',
				mode: 'no-cors',
				// cache: "no-cache",
			},
			url: '/headless/data',
		})
			.then((response) => {
				setData(response.data);
				setDevData(response.data);
			})
			.catch((e) => {
				console.error(e);
			});
	}, []);

	// Get landinger data
	useEffect(() => {
		if (useAsLandinger) {
			// const response = require('../api/landingsstatistikk/statistikk.json');
			// setLandingerData(response);
			axios({
				method: 'get',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'Access-Control-Allow-Origin': '*',
					mode: 'no-cors',
					// cache: "no-cache",
				},
				url: '/landingsstatistikk/statistikk.json',
			})
				.then((response) => {
					setLandingerData(response.data);
				})
				.catch((e) => {
					console.error(e);
				});
		}
	}, [useAsLandinger]);

	useEffect(() => {
		if (landingerData && useAsLandinger) {
			const loopThrough = (response) => {
				let arr = response.data.map((d) => {
					let harbourData = landingerData.filter(
						(l) => l[f.mottaksStasjonsNr].toLowerCase() === d.Id.toLowerCase()
					);
					let obj = {
						name: d.Navn,
						lat: d.Breddegrad,
						lng: d.Lengdegrad,
						id: d.Id,
					};
					let dates = datesArray(harbourData);
					let latest = new Date(Math.max.apply(null, dates));
					let latestFormatted = formatNewDate(latest);
					if (harbourData.length > 0) {
						obj.fDirId = harbourData[0].mottaksStasjonsFdirKode;
						obj.info = harbourData;
						obj.latest = latest;
						obj.latestFormatted = latestFormatted;
					} else {
						obj.disabled = true;
					}
					return obj;
				});

				if (Array.isArray(arr) && arr.length > 0) {
					setDataByHarbours(arr.sort((a, b) => b.latest - a.latest));
				}
			};
			// const res = require('../api/headless/AlleFiskemottak.json');
			// const response = { data: res };
			// loopThrough(response);
			axios({
				method: 'get',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'Access-Control-Allow-Origin': '*',
					mode: 'no-cors',
				},
				url: '/headless/AlleFiskemottak',
			})
				.then((response) => {
					loopThrough(response);
				})
				.catch((e) => {
					console.error(e);
				});
		}
	}, [landingerData, useAsLandinger, datesArray, formatNewDate, f]);
	useEffect(() => {
		// const res = require('../api/headless/mainmenu.json');
		// setMainMenu(res);
		axios({
			method: 'get',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Access-Control-Allow-Origin': '*',
				mode: 'no-cors',
				// cache: "no-cache",
			},
			url: '/headless/mainmenu',
		})
			.then((response) => {
				// console.log(response.data);
				setMainMenu(response.data);
			})
			.catch((e) => {
				console.error(e);
			});
	}, []);

	/* ********************************** */
	/*            SCREEN WIDTH            */
	/* ********************************** */

	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [screenHeight, setScreenHeight] = useState(window.innerHeight);
	const updateSize = () => {
		setScreenWidth(window.innerWidth);
		setScreenHeight(window.innerHeight);
	};
	useEffect(() => {
		window.addEventListener('resize', updateSize);
		return () => {
			window.removeEventListener('resize', updateSize);
		};
	}, []);

	const [devData, setDevData] = useState('');

	const useOuterClick = (callback) => {
		const callbackRef = useRef(); // initialize mutable callback ref
		const innerRef = useRef(); // returned to client, who sets the "border" element

		// update callback on each render, so second useEffect has most recent callback
		useEffect(() => {
			callbackRef.current = callback;
		});
		useEffect(() => {
			document.addEventListener('click', handleClick);
			return () => document.removeEventListener('click', handleClick);
			function handleClick(e) {
				if (
					innerRef.current &&
					callbackRef.current &&
					!innerRef.current.contains(e.target)
				)
					callbackRef.current(e);
			}
		}, []); // no dependencies -> stable click listener

		return innerRef; // convenience for client (doesn't need to init ref himself)
	};
	const average = (array) => array.reduce((a, b) => a + b) / array.length;
	function timeSince(date) {
		let formatted = new Date(
			date
				.toString()
				.replace(/\./g, '/')
				.replace(/(\d+[/])(\d+[/])/, '$2$1')
		);
		var seconds = Math.floor((new Date() - formatted) / 1000);

		var interval = seconds / 31536000;

		if (interval > 1) {
			return Math.floor(interval) + ' år';
		}
		interval = seconds / 2592000;
		if (interval > 1) {
			let d = Math.floor(interval);
			return d + ` måned${d > 1 ? 'er' : ''}`;
		}
		interval = seconds / 86400;
		if (interval > 1) {
			let d = Math.floor(interval);
			return d + ` dag${d > 1 ? 'er' : ''}`;
		}
		interval = seconds / 3600;
		if (interval > 1) {
			let d = Math.floor(interval);
			return d + ` tim${d > 1 ? 'er' : ''}`;
		}
		interval = seconds / 60;
		if (interval > 1) {
			let d = Math.floor(interval);
			return d + ` minutt${d > 1 ? 'er' : ''}`;
		}
		let d = Math.floor(interval);
		return d + ` sekund${d > 1 ? 'er' : ''}`;
	}

	const [isTouch, setIsTouch] = useState(true);
	useEffect(() => {
		if ('ontouchstart' in document.body) {
			setIsTouch(true);
		} else {
			setIsTouch(false);
		}
	}, []);

	const [isDrag, setIsDrag] = useState(true);

	useEffect(() => {
		const delta = 6;
		let startX;
		let startY;

		document.addEventListener('mousedown', function (event) {
			startX = event.pageX;
			startY = event.pageY;
		});

		document.addEventListener('mouseup', function (event) {
			const diffX = Math.abs(event.pageX - startX);
			const diffY = Math.abs(event.pageY - startY);

			if (diffX < delta && diffY < delta) {
				setIsDrag(false);
			} else {
				setIsDrag(true);
			}
		});
	}, []);
	const sum = (input) => {
		if (toString.call(input) !== '[object Array]') return false;

		var total = 0;
		for (var i = 0; i < input.length; i++) {
			if (isNaN(input[i])) {
				continue;
			}
			total += Number(input[i]);
		}
		return total;
	};
	const [canHover, setCanHover] = useState(true);
	window.addEventListener('DOMContentLoaded', (event) => {
		let ableToHover = !matchMedia('(hover: none)').matches;
		if (ableToHover) {
			document.body.classList.add('can-hover');
			setCanHover(true);
		} else {
			setCanHover(false);
		}
	});
	const Line = ({ className, onClick }) => {
		let style = {
			fill: 'none',
			stroke: '#fff',
			strokeLinecap: 'round',
			strokeMiterlimit: '10',
			strokeWidth: '3px',
		};
		return (
			<div onClick={onClick} className={`hamburger-lines ${className}`}>
				<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47.46 27.29'>
					<path
						id='Path_222'
						data-name='Path 222'
						d='M1.5,4A12.78,12.78,0,0,1,16.8,4,11.54,11.54,0,0,0,30.73,4,12.77,12.77,0,0,1,46,4'
						style={style}
					/>
					<path
						id='Path_223'
						data-name='Path 223'
						d='M1.5,13.74a12.78,12.78,0,0,1,15.3,0,11.54,11.54,0,0,0,13.93,0A12.77,12.77,0,0,1,46,13.7'
						style={style}
					/>
					<path
						id='Path_224'
						data-name='Path 224'
						d='M1.5,23.45a12.75,12.75,0,0,1,15.3,0,11.57,11.57,0,0,0,13.93,0,12.74,12.74,0,0,1,15.23,0'
						style={style}
					/>
				</svg>
			</div>
		);
	};

	const [scrolled, setScrolled] = useState(0);
	function debounced(delay, fn) {
		let timerId;
		return function (...args) {
			if (timerId) {
				clearTimeout(timerId);
			}
			timerId = setTimeout(() => {
				fn(...args);
				timerId = null;
			}, delay);
		};
	}
	window.addEventListener(
		'scroll',
		debounced(10, () => setScrolled(window.scrollY))
	);

	const [scrollDirection, setScrollDirection] = useState();
	const { isScrollingUp, isScrollingDown } = useScrollDirection();

	React.useEffect(() => {
		isScrollingDown && setScrollDirection('down');
		isScrollingUp && setScrollDirection('up');
	}, [isScrollingDown, isScrollingUp]);

	// groupBy(arr, key)
	const groupBy = function (xs, key) {
		return xs?.reduce(function (rv, x) {
			(rv[x[key]] = rv[x[key]] || []).push(x);
			return rv;
		}, {});
	};

	// groupByMulti(arr, function (item) { return [item.key1, item.key2]; });
	const groupByMulti = (arr, func) => {
		let groups = {};
		arr.forEach(function (o) {
			var group = JSON.stringify(func(o));
			groups[group] = groups[group] || [];
			groups[group].push(o);
		});
		return Object.keys(groups).map(function (group) {
			return groups[group];
		});
	};
	const [infoBoxScroll, setInfoBoxScroll] = React.useState(0);
	const formatReg = (nr, fontSize) => (
		<strong
			className='mb-0 d-inline-block'
			style={{
				fontSize: fontSize || '0.8rem',
				fontFamily: 'monospace',
			}}
		>
			{nr.replace(/\s/g, '')}
		</strong>
	);
	const formatKeys = (str) => {
		if (str) {
			const values = [
				{ old: 'baatnavn', new: 'Fartøynavn' },
				{ old: 'fangstfelt', new: 'Fangstfelt' },
				{ old: 'fiskeslag', new: 'Fiskeslag' },
				{ old: 'konserveringsMaate', new: 'Konservering' },
				{ old: 'leveringsDato', new: 'Leveringsdato' },
				{ old: 'leveringsTid', new: 'Leveringstid' },
				{ old: 'mottak', new: 'Mottak' },
				{ old: 'mottaksStasjonsFdirKode', new: 'Mottak fdir.kode' },
				{ old: 'mottaksStasjonsNr', new: 'Mottak stas.nr' },
				{ old: 'produktTilstand', new: 'Tilstand' },
				{ old: 'registermerke', new: 'Reg.nr' },
				{ old: 'rundVekt', new: 'Rundtvekt' },
				{ old: 'sortiment', new: 'Sortiment' },
			];
			values.forEach((v) => (str = str.replace(v.old, v.new)));
			return str;
		} else {
			return '';
		}
	};
	const getExtension = (item) => {
		if (item.extension) {
			return 'extension';
		} else if (item.umbracoExtension) {
			return 'umbracoExtension';
		}
	};
	// Dev stuff
	function useStickyState(defaultValue, key) {
		const [value, setValue] = React.useState(() => {
			const stickyValue = window.localStorage.getItem(key);
			return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
		});
		React.useEffect(() => {
			window.localStorage.setItem(key, JSON.stringify(value));
		}, [key, value]);
		return [value, setValue];
	}
	const [devStatus, toggleDevStatus] = useStickyState(false, 'devmode');

	// ROUTESROUTES

	const [routes, setRoutes] = React.useState();
	const [currentRoute, setCurrentRoute] = React.useState();
	const addValuesToChild = (parent, child) => {
		let obj = { parentKey: parent.key };
		// if (child.type.toLowerCase() === "avdeling") {
		//     obj["ansatteny"] = child?.ansatte?.map((ansatt) => {
		//         return {
		//             ...ansatt,
		//             url: ansatt.url.replace(/(ansatte)/, `$1/${child.slug}`),
		//         };
		//     });
		// }
		return { ...child, ...obj };
	};
	const [parentRoute, setParentRoute] = useState(null);
	const [noMoreRoutes, setNoMoreRoutes] = useState(false);
	useEffect(() => {
		if (noMoreRoutes && routes && currentRoute) {
			let r = routes?.filter((route) =>
				route.children?.filter((child) => child.key === currentRoute.key)
					.length > 0
					? true
					: false
			);
			if (r.length > 0) {
				setParentRoute(r[0]);
			} else {
				// Reset if not on a subroute
				setParentRoute(null);
			}
		}
	}, [noMoreRoutes, routes, currentRoute]);

	const evaluateChildNodes = React.useCallback((node) => {
		// If children of root has children
		if (node.children?.length > 0) {
			// Update rooutes by adding children

			setRoutes((routes) => [
				...routes,
				...node.children.map((child) => addValuesToChild(node, child)),
			]);
			// Run loop function on children
			node.children.forEach((singleNode) => {
				evaluateChildNodes(singleNode);
			});
		} else {
			// No children, done with loop
			setNoMoreRoutes(true);
		}
	}, []);
	useEffect(() => {
		if (data?.children) {
			setRoutes(
				(routes) =>
					Array.isArray(routes) // If routes is array
						? [
							...routes,
							...data.children.map((child) => addValuesToChild(data, child)),
						] // Merge both routes and children into new array
						: [
							data,
							...data.children.map((child) => addValuesToChild(data, child)),
						] // If routes is not array, initiate array with root and children
			);

			// Run loop function on children
			data.children.forEach((singleNode) => {
				evaluateChildNodes(singleNode);
			});
		} else {
			setRoutes(data);
		}
	}, [data, evaluateChildNodes]);
	const [hideHamburger, setHideHamburger] = useState(false);

	const parseRichtext = useCallback((htmlstring) => {
		let readyToParse = htmlstring.replace(
			/(<table[\S\s]*<\/table>)/g,
			"<div class='table-wrapper'>$1</div>"
		);
		return parse(readyToParse);
	}, []);

	return (
		<UmbracoContext.Provider
			value={{
				parseRichtext,
				hideHamburger,
				setHideHamburger,
				screenWidth,
				screenHeight,
				data,
				devData,
				setDevData,
				useOuterClick,
				mainMenu,
				average,
				timeSince,
				isTouch,
				isDrag,
				sum,
				canHover,
				Line,
				dataByHarbours,
				landingerData,
				formatDate,
				setUseAsLandinger,
				useAsLandinger,
				formatNewDate,
				datesArray,
				scrolled,
				scrollDirection,
				// articles,
				groupBy,
				groupByMulti,
				f,
				infoBoxScroll,
				setInfoBoxScroll,
				formatReg,
				formatKeys,
				getExtension,
				devStatus,
				toggleDevStatus,
				routes,
				currentRoute,
				setCurrentRoute,
				parentRoute,
			}}
		>
			{children}
		</UmbracoContext.Provider>
	);
};
